.headerChartsText{
    justify-content: flex-start;
    text-align: left;
    margin: 0;
    margin-top: 7%;
    padding-inline: 15px;
}
.showMore{
    margin-left: 19%;
    position: absolute;
    top: 5%;
    z-index: 700;
    
}

.boldTextCharts{
    color: #002445;
    text-align: left;
    font-weight: 700;
    margin: 0;
    margin-top: 2px;
    padding-top: 10px;
    font-size: large;
    font-weight: 700;
    text-align: left;
    font: normal normal bold 23px/32px Nutmeg;
    letter-spacing: 0px;
    color: #002445;
    opacity: 1;
}

.municipioTextBold{ 
    color: #002445;
    text-align: left;
    font: normal normal bold 25px/32px Nutmeg;
    letter-spacing: 0px;
    color: #002445;
    opacity: 1;
    margin: 0;
}

.dateText{ 
    color: #8299AD;
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10%;
}

.inputCharts{
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    padding-inline: 10px;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 3;
    height: auto; 
}

.blueTextInput{ 
    text-align: left;
    font: italic normal normal 14px/32px Nutmeg;
    letter-spacing: 0px;
    color: #002445;
    opacity: 1;
    font-weight: 700;
    margin: 0 
}

.calidadText{ 
    padding-inline: 8px;
    font-weight: auto;
    background-color: #FF9439;
    font-size: 12px;
    font-weight: 400;
    width: auto;
    padding: 1;
    border-radius: 50;
    text-align: center;
    border-radius: 10px;
    border-radius: 10px, 10px, 10px;
}

.boxContainer{
    display: flex;
    justify-content: space-around
}

.boxN{ 
    background-color: #2A7BEA;
    width: 48%;
    border-radius: 10px;
    height: 75px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.textBox{ 
    color: #FFFFFF; 
    text-align: center;
    font: italic normal normal 17px/26.5px Nutmeg;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0;
    padding-inline: 5px;
    margin-top: 7px;
}
.textNumberBox{
    color: #FFFFFF;
    text-align: center;
    font: italic normal normal 16px/32px Nutmeg;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0;
    font-weight: 600;
    margin-bottom: 7px;
}
.textFooterCharts{
    color: #6D6D6E;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 15px;
}


@media only screen and (min-width: 280px) and (max-width: 600px) {
   
    .descriptionContainer{
        flex:1; 
        opacity: 1;
        width:auto;
        margin-top: auto;
    } 

    .description{ 
        background: #F5F5F5;
        opacity: 1;
        flex-direction:row;
        flex: 1;
        height:auto;
        padding-left:15%;
        padding-right:15%;
        padding-bottom: 5%;
        width: auto;
    }

    .paragraphContainer{
        width:100%; 
    }

    .viewChartConponent {
        justify-content: center;
        flex-direction: column;
        align-self: center;
        align-items: center;
        width: 96%;
        background-color: #f5f5f5;
        overflow-x: hidden;
        overflow-y: hidden;
        height: auto !important;
        margin-left: 2%;
        border-top-left-radius: 10px;
        margin-right: 2%;
        border-top-right-radius: 10px;
        box-shadow: 5px -3px 11px 2px #999;
    }

    .viewConponentTable{
        flex-direction: row;
        justify-content: center;
        align-self: center;
        z-index: 600;
        position: absolute;
        top: 6.2rem;
        left: 0;
        width: 100vw;
        height: calc(100vh - 3rem);
        background: transparent;
        /* padding-top: 59vh; */
        margin-top: 30px;
        overflow-y: auto;
        box-sizing: border-box;
    }

    .containerWithShadow {
        width: 100%;
        padding-bottom: 5%;
        justify-content: center;
        padding-top: 0;
    }
    
    .chartDataWrapper {
        flex-direction: row;
        justify-content: center;
        align-self: center;
        z-index: 600;
        position: absolute;
        left: 0;
        width: 100vw;
        top:0;
        height: calc(100vh - 10em);
        background: transparent;
        /* padding-top: 59vh; */
        /* margin-top: 30px; */
        overflow-y: auto;
        box-sizing: border-box;
    }   

    .chartContainer {
        flex-direction: row;
        justify-content: center;
        align-self: center;
        padding-top: 1%;
    }

    .chartRowContainer {
        flex-direction: column;
        justify-content: center;
        align-self: center;
    }

    .containerWithoutShadow {
        width: auto;
        justify-content: center;
        padding-top: 0;
        margin-right: 3%;
        margin-left: 3%;
    }

    .chartRowContainer {
        flex-direction: column;
        margin-top: 15%;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .shadowContainer {
        width: 90%;
        height:15%;
        justify-content:center;
        flex-direction:row;
        padding-left:5%;
        padding-right:5%;
        padding-top:2%;
        flex-direction:row;
        display:flex;
        justify-content:flex-start
    }

} 

@media (min-width: 601px) { 

    .containerWithShadow {
        width: 90%;
        justify-content:center;
        margin-right: auto;
        margin-left: auto;
        padding-top:auto;
    }

    .containerWithoutShadow {
        width: 65%;
        justify-content:center;
        margin-right: auto;
        margin-left: auto;
        padding-top:auto;
    }

    .chartContainer{
        flex:1;
        flex-direction:row;
        justify-content:center;
        align-self:center;
        margin-top: 20%;
        margin-bottom: 20%;
    }

    .chartCirleContainer{
        flex:1;
        flex-direction:row;
        justify-content:center;
        align-self:center;
        margin-top: 15%;
    }

    .chartDataWrapper{
        flex:1;
        flex-direction:row;
        justify-content:center;
        align-self:center;
        top: 0rem;
    }

    .viewChartConponent {
        background-color: #f5f5f5;
        overflow: scroll;
    }

    .chartColmunContainer{
        flex-direction:column;
        justify-content:center;
        align-self:center;
        width: auto;
        display:flex;
        margin-top:15%;
    }

    .chartRowContainer{
        flex-direction:row;
        margin-top:15%;
        width: 100%;    
    }

    .paragraphContainer{
        width:50%;
    } 
}

