  .containerselector {
    flex: 1;
    display: flex;
    width: 100%;
    height: 3.5rem;
    flex-direction: row;
  }
 
  .scrollSelector{
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap
  }

  .boxItem{
    width: 50%;
    height: 3rem;
    justify-content: center;
    align-self: center;
    transition: background-color 1s ease 0s;
  }

  .closeButton{
    fill: "#FFFFF";
  }

  .navSmallScreen{
    flex: 1;
    display: flex;
    justify-content: space-between;
    width: auto;
    height: 5rem;
    flex-direction: row;
    background-color: '#8ACCE2';
  } 

  .box{
    height: 4rem;
    justify-content: center;
    align-self: center;
    transition: background-color 1s ease 0s;
  } 

  .nav{
    height: 3rem;
    justify-content: center;
    width: 100%;
    align-self: center;
    transition: background-color 1s ease 0s;
  }

  .boxDesktop{
    padding: auto;
    display: flex;
    position: absolute;
    z-index: 800;
    justify-content: space-around;
    flex-direction: row;
    width: 390px;
    height: 92px;
    border-bottom-left-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
  }

  p{
    justify-content: center;
    align-self: center;
    color: #FFFFFF;
    font-weight: bold
  }

  .munuOptions{
    background-color: #002F60;
    width: 50%;
    height: 4rem;
    justify-content: center;
    align-self: center;
    display: flex;
    flex-direction: row
  }

  .fullNavSmallScreen{
    width: 50%;
    height: 3rem;
    justify-content: flex-end;
    align-self: center;
    display: flex;
    padding-top: 1.5%;
    flex-direction: row
  }

  .downChevron{
    position: relative;
    justify-content: center;
    align-self:center;
    margin-left: 5%;
    margin-top:  3%;
  }

  .divTitleBlue {
    position: absolute;
    left: 6%;
    right: 7%;
    width: 85%;
    padding-inline: 5px;
    height: 45px;
    border-radius: 20px 20px 20px;
    opacity: 1;
    font: italic normal normal 14px/32px Nutmeg;
    display: flex;
} 

.modalOut{
  z-index: 600;
}

