
.showMoreStatistic{ margin-left: 19%;
    position: absolute;
    top: 108px;
    z-index: 700;
}
.showMoreFiles{ margin-left: 19%;
    position: absolute;
    top: 155px;
    z-index: 700;
}
.iconsContainer{
    top: 40%;
    z-index: 700;
    background-color: violet;
}

@media only screen and (min-width: 280px)  {
    .mapContainerPrivate {
        width: 100vw;
        height: 59vh;
    }
} 

.sessionOutContainer{
    top: 10%;
    z-index: 700;
    background-color: violet;
}
.out{
    margin-left: 19%;
    position: absolute;
    top: 10px;
    z-index: 700;

}
.key{
    margin-left: 19%;
    position: absolute;
    top:85px;
    z-index: 700;
}

@media (min-width: 700px) { 
    .mapContainerPrivate {
        position: absolute;
        width: 90vw;
        height: 98vh;
        margin-top: 72px;
        margin-left: 270px !important;
    }
}
