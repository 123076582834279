.headerFiles{
    display: flex;
    margin-top: 20px;
}
.titleFiles{
    background-color: #244C71;
    color: white;
    border-top-right-radius: 10px;
    padding: 5px;
    padding-inline: 0px;
    width: 80%;

}
.btnAddFile{
    margin: 5px auto;
    border: none;
    padding: 10px 10px;
    font-size: 10px;
    position: relative;
    border-radius: 30px;
    color: #FFFFFF;
    background-color: #022445;
}
.btnAddFileMin{
  /* margin: 5px auto; */
  border: none;
  /* padding-inline: 5px 25px; */
  width: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 10px;
  position: relative;
  border-radius: 30px;
  color: #FFFFFF;
  margin-left: 65%;
  margin-bottom: 10px;
  margin-top: -10px;
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
#customers td, #customers th {
  border: 1px solid #ddd;
  padding-inline: 8px;
  padding-top: 0;
  font-size: 10px;
  padding-bottom: 0;
  color: #70707080;

}

#customers tr:nth-child(even){
    background-color: #CED7E1;
    color: #70707080;
  }

#customers tr:hover {
    background-color: #ddd; 
    color: #70707080;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #FFFFFF;
  color: #62686E;
}
.btn{
  margin: 5px auto;
  border: none;
  padding: 4px 20px;
  font-size: 8px;
  position: relative;
  border-radius: 30px;
  color: #FFFFFF;
  background-color: #0E5FC5;
}
/* .viewChartConponent{
     top: 72px !important;
} */