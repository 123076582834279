.listContainer{
    width: 100%;
    background-color: #002445;
    overflow-x:hidden;
    overflow-y:scroll;
    z-index: 700;
    position: absolute;
}

.item{
    background-color: #002445;
    display: flex;
    height: 70px;
    padding-left: 14%;
    padding-right: 12%;
    border-bottom: 1px solid #FFFFFF;
}
.itemContainer :hover {
    animation: animate 5s infinite linear;
    background-color: #244C71;
    cursor: pointer;
  }

.porcentaje{ 
    font-size: 22px;
    /* color: #206AAD;
    background-color: #BEE6F4; */
    padding-top: 3%;
    padding-bottom: 3%;
    padding-inline: 2%;
    align-self: center;
    border-top-left-radius: 10px 10px;
    border-bottom-left-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
    margin: 0;
    font-weight: 700;
}

.municipioText{
    color: #8ACCE2;
    font-size: small;
    margin: 1%;
    height: 40px;
    width: 150px;
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.textContainer{
    justify-content: center;
    flex-direction: column;
    text-align: initial;
    justify-content: center;
    text-align: start;
    padding-left: 5%;
    padding-top: 2%;
    padding-bottom: 5%;
    margin-bottom: 10%;
    align-self: baseline;
}

.condicionContainer{
    text-align: initial;
    height: 0;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    font-size: small !important; 
    font-weight: lighter;
}

@media (min-width: 800px) {
    .titleContainerList{
        width: 273px;
        background-color: #002F60;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: -50px;
        /* height: 185px; */
        padding-top: 40px;
        z-index: 700;
        position: absolute;
    }

    .divTitleBlue {
        position: absolute;
        top: 165px;
        left: 38px;
        width: 194px;
        cursor: pointer;
        /* justify-content: center; */
        padding-inline: 5px;
        height: 45px;
        /* background: #005FC5 0% 0% no-repeat padding-box; */
        border-radius: 20px 20px 20px;
        opacity: 1;
        font: italic normal normal 14px/32px Nutmeg;
        display: flex;
        /* position: absolute;
        top: 165px;
        left: 38px;
        width: 194px;
        height: 29px;
        background: #005FC5 0% 0% no-repeat padding-box;
        border-radius: 13px 0px;
        opacity: 1;
        font: italic normal normal 14px/32px Nutmeg; */
    }
    .divTitleBluePrivate {
        position: absolute;
        top: 80px;
        left: 38px;
        width: 194px;
        height: 29px;
        background: #005FC5 0% 0% no-repeat padding-box;
        border-radius: 13px 0px;
        opacity: 1;
        font: italic normal normal 14px/32px Nutmeg;
    }

    .listContainer{
        width: 273px;
        padding-top: 15px;
        z-index: 700;
        position: absolute;
    }

    .item{
        background-color: #002445;
        display: flex;
        height: 70px;
        padding-left: 14%;
        padding-right: 12%;
    }

    .boldText{
        font-weight: bolder;
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        /* margin-inline: 5%; */
        margin-bottom: 2%;
        /* margin-top: 60px; */
   }

    .lightText{
        font-weight: 100;
        font-size: 15px;
        color: #FFFFFF;
        font: normal normal normal Nutmeg;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 0;
        margin-bottom: 0%;
        padding-bottom: 90px;     
        margin-top: 0;
    }

    .mediumText{
        margin-left: 5%;
        text-align: left;
        font: normal normal bold 16px/43px Nutmeg;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        /* margin-top: 0; */
        margin-bottom: 0%;
        padding-bottom: 90px;
        margin-top: 0;
   }
} 