@font-face {
  
  font-family: 'Nutmeg Headline Ultra';
  src: url('./Nutmeg-UltraBlack.woff2') format('woff2'),
  url('./NutmegHeadline-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  
  font-family: 'Nutmeg Book';
  src:url('./Nutmeg-BookItalic.woff2') format('woff2'),
 url('./Nutmeg-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  
  font-family: 'Nutmeg Regular';
  src:url('./Nutmeg-RegularItalic.woff2') format('woff2'),
 url('./Nutmeg-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  
  font-family: 'Nutmeg Book';
  src:url('./Nutmeg-Book.woff2') format('woff2'),
 url('./Nutmeg-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg';
  src:url('./Nutmeg-Thin.woff2') format('woff2'),
 url('./Nutmeg-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline Ultra';
  src:url('./NutmegHeadline-UltraLight.woff2') format('woff2'),
 url('./NutmegHeadline-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline Extra Bold Ital';
  src:url('./NutmegHeadline-ExtBdIta.woff2') format('woff2'),
 url('./NutmegHeadline-ExtBdIta.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-Thin.woff2') format('woff2'),
 url('./NutmegHeadline-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-Regular.woff2') format('woff2'),
 url('./NutmegHeadline-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline Ultra Light Ita';
  src:url('./NutmegHeadline-UltLtIta.woff2') format('woff2'),
 url('./NutmegHeadline-UltLtIta.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Ultra';
  src:url('./Nutmeg-UltraLight.woff2') format('woff2'),
 url('./Nutmeg-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline Book';
  src:url('./NutmegHeadline-BookItalic.woff2') format('woff2'),
 url('./NutmegHeadline-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  
  font-family: 'Nutmeg';
  src:url('./Nutmeg-Regular.woff2') format('woff2'),
 url('./Nutmeg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Ultra';
  src:url('./Nutmeg-UltraLightItalic.woff2') format('woff2'),
 url('./Nutmeg-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-Bold.woff2') format('woff2'),
 url('./NutmegHeadline-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-BoldItalic.woff2') format('woff2'),
 url('./NutmegHeadline-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-Light.woff2') format('woff2'),
 url('./NutmegHeadline-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-Black.woff2') format('woff2'),
 url('./NutmegHeadline-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg Headline Extra';
  src:url('./NutmegHeadline-ExtraBold.woff2') format('woff2'),
 url('./NutmegHeadline-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg';
  src:url('./Nutmeg-Light.woff2') format('woff2'),
 url('./Nutmeg-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  
  font-family: 'Nutmeg';
  src:url('./Nutmeg-LightItalic.woff2') format('woff2'),
 url('./Nutmeg-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  
  font-family: 'Nutmeg Headline Regular';
  src:url('./NutmegHeadline-RegularItalic.woff2') format('woff2'),
 url('./NutmegHeadline-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Nutmeg Headline Ultra Black Ita';
  src:url('./NutmegHeadline-UltBlkIta.woff2') format('woff2'),
 url('./NutmegHeadline-UltBlkIta.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Nutmeg';
  src:url('./Nutmeg-Bold.woff2') format('woff2'),
 url('./Nutmeg-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Nutmeg';
  src:url('./Nutmeg-Black.woff2') format('woff2'),
 url('./Nutmeg-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-LightItalic.woff2') format('woff2'),
 url('./NutmegHeadline-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Nutmeg Extra';
  src:url('./Nutmeg-ExtraBold.woff2') format('woff2'),
 url('./Nutmeg-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Nutmeg';
  src:url('./Nutmeg-BlackItalic.woff2') format('woff2'),
 url('./Nutmeg-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-BlackItalic.woff2') format('woff2'),
 url('./NutmegHeadline-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Nutmeg';
  src:url('./Nutmeg-ThinItalic.woff2') format('woff2'),
 url('./Nutmeg-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Nutmeg Headline Book';
  src:url('./NutmegHeadline-Book.woff2') format('woff2'),
 url('./NutmegHeadline-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Nutmeg Ultra';
  src:url('./Nutmeg-UltraBlack.woff2') format('woff2'),
 url('./Nutmeg-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Nutmeg Ultra';
  src:url('./Nutmeg-UltraBlackItalic.woff2') format('woff2'),
 url('./Nutmeg-UltraBlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Nutmeg Headline';
  src:url('./NutmegHeadline-ThinItalic.woff2') format('woff2'),
 url('./NutmegHeadline-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Nutmeg Extra';
  src:url('./Nutmeg-ExtraBoldItalic.woff2') format('woff2'),
 url('./Nutmeg-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Nutmeg';
  src:url('./Nutmeg-BoldItalic.woff2') format('woff2'),
 url('./Nutmeg-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}