@import '../../assets/fonts/Nutmeg/index.css';

body{
    overflow-y:hidden;
}

.leaflet-top, .leaflet-bottom{
    z-index: 500 !important;
}

/* .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom{
    height: 100% !important;
} */

::-webkit-scrollbar {
    display: none;
}

.screen{
    overflow-x:hidden;
    overflow-y:hidden; 
}

.blueContainer{
    background-color:#8ACCE2;
    height: auto;
}

.branding{
    flex-direction:column;
    display:flex;
    align-content:center;
    align-items:center;
    justify-content:space-between;
    height:50%;
    padding-top:3%;
}

.bannerContainer{
    width:"90%";
    margin-bottom: -2%;
}

.bannerTitle{
    font-weight: bold;
    text-align: center;
    font: normal normal bold 42px/100px Nutmeg;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 3px 35px #22222240;
    opacity: 1;
    font-size: 62;
}

.nabvarContainer{
    width: 100%;
    position: fixed;
    top:0;         
}

.descriptionContainer{
    flex:1; 
    opacity: 1;
    width:auto;
    margin-top: 10%;
} 

.description{ 
    background: #F5F5F5;
    opacity: 1;
    flex-direction:row;
    flex: 1;
    height:auto;
    padding-left:15%;
    padding-right:15%;
    width: auto;
}

.map{
    flex-direction: row;
    justify-content: space-between;
    margin-inline: auto;
    align-self:center;
    width: 100%;
}

.titleMap{
    width: 80%;
    margin-inline: auto;
    flex-direction: row;
    justify-content: space-between;
    align-self:center;
    text-align: center;
    margin-block: 3%; 
}

.leaflet-container {
    position: relative;
    /* width: inherit;
    height: inherit; */
    width: 100%;
    height: 100vh;
}

.mapTitleBlue{
    color: #3580CD;
    width: 50%;
    margin-inline: auto;
    flex-direction: row;
    justify-content: space-between;
    align-self:center;
    text-align: center;
}

.mapDropdown {
    width: 292px;
    height: 50px;
    display: inline-block;
    margin-left: 20px;
    margin-top: 110px;
    margin-right: 10px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 5px #b4b4b498;
    border-radius: 25px;
    text-align: left;
    padding-left: 30px;
}

.mapDropdown:hover {
    background: #eceef0;
}

.mapDropdown input {
    display: none;
}

.dropdownLegend {
    display: block;
    margin-top: 2%;
    margin-bottom: 1%;
    color: #7a7a7a;
    font-size: 12px;
    cursor: pointer;
}

.dropdownLegend::after {
    content: "";
    float: right;
    margin: 15px 15px 0 0;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #7a7a7a;
}

.dropdownYear {
    display: block;
    color: #7d888d;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.mapDropdown ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 80%;
    margin: 0;
    padding: 0;
    background: white;
    box-shadow: 0px 3px 6px #b4b4b498;
    border-radius: 5px;
    cursor: pointer;
}

.mapDropdown li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    width: 100%;
    color: #7d888d;
    font-size: 14px;
    overflow: hidden;
    transition: all 0.3s;
}

.activeYear {
    background: #eceef0;
}

.mapDropdown li:hover {
    background: #eceef0;
    color: #666;
}
.mapDropdown input:checked ~ ul li {
    height: 40px;
}

.logos{
    flex-direction:column;
    position:absolute ;
    width:90%;
    justify-content:space-around;
    flex-direction:column;
}
.shadowContainer {
    width: 90%;
    height:15%;
    justify-content:center;
    flex-direction:row;
    padding-left:5%;
    padding-right:5%;
    padding-top:2%;
    flex-direction:row;
    display:flex;
    justify-content:flex-start
} 

.p {
    text-align: left;
    font-family:Nutmeg;
    font-weight: bold;
    font-size:15;
    color: #FFFFFF;
    opacity: 1;
    font-variant:Nutmeg;
}

.logoJalisco{
    justify-content:space-between;
    flex-direction:column;
}

.listContainer{
    /* width: 100%; */
    background-color: #002445;
    overflow-x:hidden;
    overflow-y:scroll;
}

@media only screen and (min-width: 280px)  {
    /* screen */

    .leaflet-container {
        position: relative;
        /* width: inherit;
        height: inherit; */
        width: 100%;
        height: 80vh;
    }

    .charts{
        position: absolute;
        background-color: darkblue;
    }

    /* mapa */
    .mapCont{
        /* position: absolute; */
        top: 0%;
        width: 100%;
        height: auto;
    }
    .mapContainer {
        width: 100vw;
        height: 59vh;
    }

    .cardMap{
        width: 100%;
        height: 100%;
    }

    .blueContainer{
        background-color:#8ACCE2;
        width: auto; 
        height: auto;
    }

    .branding{
        flex-direction:column;
        display:flex;
        width: auto;
        align-content:center;
        align-items:center;
        justify-content:space-between;
        height:50%;
        padding-top:3%;
    }

    .bannerContainer{
        width:100%;
        margin-bottom: -2%;
    }

    .bannerTitle{
        opacity: 1;
        position:absolute;
        width:100%;
        margin-top:30%;
        text-align: center;
        font: normal normal bold 42px/100px Nutmeg; 
        letter-spacing: 0px;
        color: #FFFFFF;
        text-shadow: 0px 3px 35px #22222240;
        opacity: 1;
    }

    /* navbar */
    .nabvarContainer{
        width: 100%;
        position: fixed;
        top:0;         
    }
    .descriptionContainer{
        flex:1; 
        opacity: 1;
        width:auto;
        margin-top: auto;
    } 
    .description{ 
        background: #F5F5F5;
        opacity: 1;
        flex-direction:row;
        flex: 1;
        height:auto;
        padding-left:15%;
        padding-right:15%;
        padding-bottom: 5%;
        width: auto;
    }
    .paragraphContainer{
        width:100%; 
    }
    
    .logos{
        flex-direction:column;
        position:absolute ;
        width:90%;
        justify-content:space-around;
        flex-direction:column;
    }
    .shadowContainer {
        width: 90%;
        height:15%;
        justify-content:center;
        flex-direction:row;
        padding-left:5%;
        padding-right:5%;
        padding-top:2%;
        flex-direction:row;
        display:flex;
        justify-content:flex-start
    }
   
    .p {
        text-align: left;
        font-family:Nutmeg;
        font-weight: bold;
        font-size:15;
        color: #FFFFFF;
        opacity: 1;
        font-variant:Nutmeg;
    }

    .logoSantiago{
    width: 45%;
    margin-top: 45%;
    }

    .logoJalisco{
        margin-top: 10%;
    }

} 

@media (min-width: 700px) { 

    .leaflet-container {
        position: relative;
        /* width: inherit;
        height: inherit; */
        width: 100%;
        height: 100%;
    }

    .bodyScreen{
        display: table;
        width: 100%;
        height:100%;
    }
    .cardMap{
        width: 273px;
    }

    .mapCont{
        position: absolute !important;
        top: 0%;
        width: 50%;
        height: auto;
    }
    .mapContainer {
        position: absolute;
        /* width: 55vw; */
        height: 100vh;
        /* margin-left: 270px !important; */
    }
    .mapContainerL   {
        position: absolute;
        width: 70vw;
        height: 100vh;
        /* margin-left: 270px !important;  */
    }
    
    .titleContainerList{
        z-index: 700;
        position: absolute;
    }
    
    /* charts */

    .viewChartConponent{ 
        border-radius: 10px;
        position: absolute;
        top: 0;
        border-radius: 10;
        width: 100%;
        margin: 0;
        height: 802px;
        position: absolute;
        z-index: 600;
        top: 30%;
      
        
    }
    
    /* map */

    .titleMap{
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-self:center;
        text-align: center;
        
    }
    .mapTitleBlue{
        color: #3580CD;
        font-size:20px;
        height: auto;
        font-family: Nutmeg;
        font-weight: bold;
    }
    
    .p {
        text-align: left;
        font-family:Nutmeg;
        font-weight: bold;
        font-size:15;
        color: #FFFFFF;
        opacity: 1;
        font-variant:Nutmeg;
        width: 85%;
        flex-wrap: nowrap;
        text-align: left;
    }
  
    .logoSantiago{
        width:20%;
        margin: 0%;
        margin-top: 15%;
        padding-left: 6%;
    }
    .logoJalisco{
        margin-top: 0%;
        height: 20%;
    }
    .goBack{
        position: absolute;
        top: 2%;
        z-index: 700;
    }

    .goBackText{
        margin-left: 60%;
        color: #8ACCE2;
        text-align: left;
        font: italic normal bold 16px/32px Nutmeg;
        letter-spacing: 0px;
        color: #8ACCE2;
        opacity: 1;
        position: absolute;
        top: .5%;
        z-index: 700;
        padding-bottom: 2%;
    }
    
}

