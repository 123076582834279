.modalContainer{
  background-color:#002F5D;
  width:100wh; 
  height:100vh; 
  justify-content:center
}

.boxModal{
  background-color:#CED7E1; 
  width:453px; 
  height:301px; 
  border-radius:30px 30px 30px;
  justify-content:center;
  margin-top:10%;
  display:inline-block;
  padding-bottom: 5%;
}

.imgHeader{
  margin-top:4%;
  justify-content:space-between;
  padding-top:20px;
  margin-inline: 10%;
  display: flex
}

.labelAlertSubmit{
  margin: 0;
  padding: 0;
  color: #022445;
  text-align: center;
  width: 196px;
  padding-inline: 29%;
  font-weight: lighter;
  font-size: 12px;
}

.labelModal{
  color:#002445;
  padding-top: 5%;
}

.labelExitoModal{
  color:#002445;
}

input[type="file"] {
  display: none;
}

.text{
  margin: 0;
  padding: 0;
  color: #0E5FC5;
  text-align: end;
  width: 160px;
}

.custom-file-upload {
  cursor: pointer;
  font-style: normal !important;  
  font-family: Open Sans,Verdana !important;
  font-size: 12px;
}

.buttonOkModalExito{
  font-family: Open Sans,Verdana;
  font-size: 12px;
  background-color:#FFFFFF;
  color: #FFFFFF;
  padding-top:2%;
  padding-bottom:2%;
  margin-top:10%;
  padding-inline:5%;
  width: 120px;
  border-radius:30px 30px 30px;
  margin-left: 33%;
  margin-right: 33%;
  font-weight: bolder;
  cursor: pointer;
}

.buttonOkModal{
  font-family: Open Sans,Verdana;
  font-size: 12px;
  background-color:#FFFFFF;
  color: #FFFFFF;
  padding-top:2%;
  padding-bottom:2%;
  margin-top:10%;
  padding-inline:5%;
  width: 120px;
  border-radius:30px 30px 30px;
  margin-left: 5%;
  margin-right: 5%;
  font-weight: bolder;
}

.okButton{
  margin: 0;
  padding: 0;
  color: #0E5FC5;
  text-align: center;
  width: auto;
  height: 10px;
}

.buttonOkModal:hover {
  background-color:#005FC5 ;
  color: #0E5FC5;
  font-weight: bolder;
  /* margin: 0;
  padding: 0; */
  /* height: 10px; */
}

input[type="file"]:active {
  background-color:#CED7E1 ;
  color: #0E5FC5;
  font-weight: bolder;
}


.buttonOkModal:active {
  box-shadow: 0 5px #005FC5 ;
  transform: translateY(4px);
  background-color:#005FC5 ;
  font-weight: bolder;
  color: #FFFFFF; 
  /* height: auto; */
}

.input-text {
  font-weight:300;
  width: 400px;
  font-size: 25px;
  border:0px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #4b5158;
  background: #CED7E1;
  font-family: Open Sans,Verdana;
  padding: 10px 15px;
  margin: 20px 10px 0px;
  transition: all 0.1s ease-in-out;
  border-bottom:1px solid#002F5D;

  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
}

.input-text:focus {
  border-bottom:1px solid #292c30;
}

.ghost-button {
  background-color: transparent;
  border:2px solid #292c30;
  padding:10px 30px; 
  min-width: 20px;
  max-width: 80px;

  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.buttonContainer{
  display: flex;
  width: 60%;
  padding-inline: 20%;
}

@media only screen and (min-width: 280px)  {
.boxModal {
  background-color: #CED7E1;
  width: 80%;
  height: auto;
  border-radius: 30px 30px 30px;
  justify-content: center;
  margin-top: 50%;
  display: inline-block;
  padding-bottom: 5%;
}
.buttonContainer{
  display: flex;
  width: 60%;
  padding-inline: auto;
}

.labelAlertSubmit{
  margin: 0;
  padding: 0;
  color: #022445;
  text-align: center;
  width: 196px;
  padding-inline: 19%;
  padding-bottom: 5%;
  font-weight: lighter;
  font-size: 12px;
}

.buttonOkModalExito{
  font-family: Open Sans,Verdana;
  font-size: 12px;
  background-color:#FFFFFF;
  color: #FFFFFF;
  padding-top:2%;
  padding-bottom:2%;
  margin-top:10%;
  padding-inline:5%;
  width: 120px;
  border-radius:30px 30px 30px;
  margin-left: 26%;
  margin-right: 33%;
  font-weight: bolder;
  cursor: pointer;
}
}

@media only screen and (min-width: 700px)  {
  .boxModal {
    background-color: #CED7E1;
    width: 40%;
    height: auto;
    border-radius: 30px 30px 30px;
    justify-content: center;
    margin-top: 10%;
    display: inline-block;
    padding-bottom: 5%;
  }
  .labelAlertSubmit{
    margin: 0;
    padding: 0;
    color: #022445;
    text-align: center;
    width: 196px;
    padding-inline: 35%;
    padding-bottom: 5%;
    font-weight: lighter;
    font-size: 12px;
  }
  .buttonContainer{
    display: flex;
    width: 60%;
    padding-inline: 25%;
    justify-content: space-around;
  }
}
